import { useTranslation } from "react-i18next";
import { Ellipse } from "../../../components";
import { getImage } from "../../../utils";
import { useParameters } from "../useParameters";
import "./ForgotPassword.scss";
import { useEffect } from "react";
import { APPLE_STORE_LINK, GOOGLEPLAY_STORE_LINK, LINKEDIN_LINK } from "../../../config/constante";

// page / component
// use the function typing
export function ForgotPassword(): JSX.Element {
    const parameters = useParameters("code");
    const { t } = useTranslation();

    /**
     * Sets the background color of the body element to '#f6f6f6' when the component mounts.
     * Resets the background color when the component unmounts.
     */
    useEffect(() => {
        document.body.style.backgroundColor = '#f6f6f6';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    return (
        <table className="mainTable">
            {/* <!-- HEADER --> */}
            <thead>
                <tr>
                    <th colSpan={2} className="ellipse-1">
                        <Ellipse height={120} />
                    </th>
                </tr>
            </thead>
            {/* <!-- END HEADER --> */}
            {/* <!-- MAIN CONTENT --> */}
            <tbody>
                <tr>
                    <td className="img">
                        <a
                            href="https://reachup.app/"
                            aria-label="Lien vers le site web de Reach Up"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={getImage("Logo-min.png")}
                                alt="Reach Up logo"
                                width="209"
                                height="90"
                            />
                        </a>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="email_title" >
                        <p>
                            {t("Email_forgotPass_title")}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="email_content">
                        <p>{t("Email_forgotPass_subTitle")}</p>
                        <p>
                            {t("Email_forgotPass_text")}
                        </p>
                        <p>{t("Email_forgotPass_details")}</p>
                        <p style={{ textAlign: "center" }}>
                            ({t("Email_forgotPass_validity")})
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={2}
                        style={{ paddingTop: 0 }}
                    >
                        <table className="email_content-table">
                            <tbody>
                                <tr>
                                    <td className="label">
                                        {t("Email_forgotPass_id")}
                                    </td>
                                    <td className="value">{parameters.code}</td>
                                </tr>
                                <tr className="code-section">
                                    <td className="code-section__container" colSpan={2}>
                                        <a
                                            className="code-section__link"
                                            aria-label="Lien vers la page où insérer le code de récupération"
                                            href={`https://app.reachup.app/login?status=verification_code&code=${parameters.code}`}
                                        >
                                            {t("Email_forgotPass_link")}
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ textAlign: "start", paddingBottom: 0 }} >
                                        <a href={APPLE_STORE_LINK} target="_blank" rel="noreferrer" aria-label="Lien vers l'Apple Store">
                                            <img
                                                src={getImage("EmailAppleStore.png")}
                                                alt="Apple store logo"
                                                width={110}
                                                height={50}
                                            />
                                        </a>
                                    </td>
                                    <td style={{ textAlign: "end", paddingBottom: 0 }}>
                                        <a
                                            href={GOOGLEPLAY_STORE_LINK}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="Lien vers le Goggle Play Store"
                                        >
                                            <img
                                                src={getImage("EmailGooglePlay.png")}
                                                alt="Google Play logo"
                                                width={110}
                                                height={50}
                                            />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ height: 1, textAlign: 'center' }}>
                        <div className="row-to-reduce"></div>
                    </td>
                </tr>
                <tr className="email_content-bottom">
                    <td >
                        <a
                            href="https://reachup.app/"
                            aria-label="Lien vers le site web de Reach Up"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={getImage("Logo.png")}
                                alt="Reach Up logo"
                                width="135"
                            />
                        </a>
                    </td>
                    <td style={{ textAlign: 'center' }} >
                        <a
                            href={LINKEDIN_LINK}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Lien vers la profil LinkedIn de Reach Up"
                        >
                            <img
                                src={getImage("linkedin.png")}
                                alt="Linkedin logo"
                                width="21"
                                height="23"
                            />
                        </a>
                    </td>
                </tr>
                {/* <!-- END MAIN CONTENT --> */}
                {/* <!-- FOOTER --> */}
                <tr>
                    <td colSpan={2}>
                        <table className="footer_table">
                            <tbody>
                                <tr>
                                    <td className="footer-value" >
                                        <a
                                            href="https://reachup.app/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="reachUp-link"
                                            aria-label="Lien vers le site web de Reach Up"
                                        >
                                            {t("Email__footer_ReachUp")}
                                        </a>
                                    </td>
                                    <td className="footer-value">&bull;</td>
                                    <td className="footer-value">
                                        <p>{t("Email__footer_adress")}</p>
                                    </td>
                                    <td className="footer-value">&bull;</td>
                                    <td className="footer-value">
                                        <p>{t("Email__footer_city")}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                {/* <!-- END FOOTER --> */}
            </tbody>
        </table>
    );
};
